import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import Particles from "react-particles-js"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typist from "react-typist"
import {
	GlobalStateContext,
} from "../context/GlobalContextProvider"
import { GlobalState } from "../types/types"

const IndexPage = () => {

	const [btnHovered, setBtnHovered] = useState(false);
	const state = useContext(GlobalStateContext) as GlobalState

	return (
		<Layout>
			<SEO
				title={'Home'}
				image={{src:'/home.png',width:1573,height:785}}
				pathname={'/home.png'}
			/>
			<div className="home-particles-wrapper">
				<div className="intro-text-wrapper">
					<Typist cursor={{ hideWhenDone: true }}>
						<Typist.Delay ms={500} />
						thanks for visiting!<br></br>
						check out <Link onMouseEnter={() => setBtnHovered(true)} onMouseLeave={() => setBtnHovered(false)} to="/my-work">My Work...</Link>
					</Typist>
				</div>
				<div className="home-particles">

					{state.mainPageAnimations && <Particles
						params={{
							"particles": {
								"number": {
									"value": 160,
									"density": {
										"enable": false
									}
								},
								"size": {
									"value": 3,
									"random": true,
									"anim": {
										"speed": 4,
										"size_min": 0.3
									}
								},
								"line_linked": {
									"enable": false
								},
								"move": {
									"random": true,
									"speed": btnHovered ? 50 : 3,
									"direction": btnHovered ? 'none' : "top-right",
									"out_mode": "bounce"
								}
							},
							"interactivity": {
								"events": {
									"onhover": {
										"enable": true,
										"mode": "grab"
									},
									"onclick": {
										"enable": true,
										"mode": "repulse"
									}
								},
								"modes": {
									"bubble": {
										"distance": 250,
										"duration": 2,
										"size": 0,
										"opacity": 0
									},
									"repulse": {
										"distance": 300,
										"duration": 3
									}
								}
							}

						}} />}

				</div>
			</div>
		</Layout>
	)
}



export default IndexPage
